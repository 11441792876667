import { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

type QueryTypes = 'payment'

export const useTypedSearchParams = () => {
  const [params] = useSearchParams()

  const getAllSearchParams = useMemo(() => {
    const allParams: Partial<Record<QueryTypes, string>> = {}

    params.forEach((value, key) => {
      allParams[key as QueryTypes] = value
    })

    return allParams
  }, [params])

  return {
    getAllSearchParams,
  }
}
