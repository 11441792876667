import { action, makeAutoObservable, observable } from 'mobx'
import { UserMe } from 'shared/api/gen/types'

export class UserStore {
  @observable private _user: UserMe | null = null

  constructor() {
    makeAutoObservable(this, {}, { deep: true })
  }

  @action public clearUser = () => {
    this._user = null
  }

  @action public setUser = (user: UserMe) => {
    this._user = user
  }

  get user() {
    return this._user
  }
}
