import { captureException } from '@sentry/react'
import { AboutProil } from 'pages/about-proil'
import { Contacts } from 'pages/contacts'
import { Documents } from 'pages/documents'
import { ApiErrorPage, NotFoundPage } from 'pages/error-pages'
import { FinalOrder } from 'pages/final-order'
import { MainPage } from 'pages/main-page'
import { Orders } from 'pages/orders'
import { TempAuth } from 'pages/temp-auth'
import { Suspense } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { createBrowserRouter, RouteObject } from 'react-router-dom'
import appModeIsDev from 'shared/configs/app-mode'
import { ENV } from 'shared/configs/env'
import {
  AppRoutesNames,
  OrdersChildNames,
  OrdersChildRoutes,
  RootRouterNames,
  RoutersPaths,
  RoutersPathsWithRoot,
} from 'shared/configs/router-consts'
import PagesSkeletons from 'shared/ui/PagesSkeletons'
import { convertObjRoutersToArray } from 'widgets/header/lib/convert-obj-routers-to-array'

import { RootRoute } from '../ui/RootRoute'
import ProtectedRoute from './ProtectedRoute'

const convertAndAddSuspense = (obj: Record<string, RouteObject>) =>
  convertObjRoutersToArray(obj).map((el) => {
    el.element = (
      <ErrorBoundary onError={(err) => captureException(err)} fallback={<ApiErrorPage />}>
        <Suspense fallback={<PagesSkeletons />} children={el.element} />
      </ErrorBoundary>
    )

    return el
  })

const OrderCreateChieldsObj: Record<OrdersChildNames, RouteObject> = {
  [OrdersChildNames.finishing]: {
    path: OrdersChildRoutes[OrdersChildNames.finishing].url,
    element: <FinalOrder />,
  },
}

const OrderCreateChieldsArr = convertAndAddSuspense(OrderCreateChieldsObj)

const AppRoutesObj: Record<AppRoutesNames, RouteObject> = {
  [AppRoutesNames.main]: {
    path: RoutersPaths[AppRoutesNames.main].url,
    element: <MainPage />,
    children: OrderCreateChieldsArr,
  },

  [AppRoutesNames.orders]: {
    path: RoutersPaths[AppRoutesNames.orders].url,
    element: <Orders />,
  },

  [AppRoutesNames.aboutProil]: {
    path: RoutersPaths[AppRoutesNames.aboutProil].url,
    element: <AboutProil />,
  },

  [AppRoutesNames.contacts]: {
    path: RoutersPaths[AppRoutesNames.contacts].url,
    element: <Contacts />,
  },

  [AppRoutesNames.documents]: {
    path: RoutersPaths[AppRoutesNames.documents].url,
    element: <Documents />,
  },

  [AppRoutesNames.any]: {
    path: RoutersPaths[AppRoutesNames.any].url,
    element: <NotFoundPage />,
  },
}

const RootRouterObj: Record<RootRouterNames, RouteObject> = {
  [RootRouterNames.root]: {
    path: RoutersPathsWithRoot[RootRouterNames.root].url,
    element: appModeIsDev ? (
      <>
        <ProtectedRoute redirect="/auth" authToken={localStorage.getItem('test') ?? ''}>
          <RootRoute />
        </ProtectedRoute>
        {!localStorage.getItem('test') && <TempAuth />}
      </>
    ) : (
      <RootRoute />
    ),
    children: convertAndAddSuspense(AppRoutesObj),
  },
}

const routers = convertAndAddSuspense(RootRouterObj)

export const routerConfig = createBrowserRouter(routers, { basename: ENV.PUBLIC_URL })
