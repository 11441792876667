import { QueryKey, UseQueryOptions } from '@tanstack/react-query'
import { useLayoutEffect } from 'react'
import { useGetUserMe } from 'shared/api'
import { ErrorType } from 'shared/api/gen/axios'
import { ApiError, UserMe } from 'shared/api/gen/types'

import { useUserStore } from './useUserStore'

export const useGetUserAndSave = (
  options?: Partial<UseQueryOptions<UserMe, ErrorType<ApiError>, UserMe, QueryKey>>
) => {
  const query = useGetUserMe(
    {},
    { query: { refetchOnWindowFocus: false, retry: false, ...options } }
  )

  const { setUser } = useUserStore()
  const { isLoading, isSuccess, data } = query

  useLayoutEffect(() => {
    isSuccess && setUser(data)
  }, [data, isLoading, isSuccess, setUser])

  return query
}
