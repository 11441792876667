import { createSearchParams, useNavigate } from 'react-router-dom'
import { AppRoutesNames, OrdersChildNames, RoutersPaths } from 'shared/configs/router-consts'

import { useTypedSearchParams } from './useTypedSearchParams'

type Route = { key: AppRoutesNames; childKey?: OrdersChildNames }

const useTypedNavigate = (saveQuery = true) => {
  const { getAllSearchParams } = useTypedSearchParams()

  const navigate = useNavigate()

  const typedNavigate = (route: Route) => {
    const basePath = RoutersPaths[route.key].url
    const childPath = route.childKey ? RoutersPaths[route.key].child?.[route.childKey].url : ''
    const path = childPath ? childPath : basePath

    navigate({
      pathname: path,
      search: saveQuery ? createSearchParams(getAllSearchParams).toString() : '',
    })
  }

  return { typedNavigate }
}

export default useTypedNavigate
