import { BrowserTracing, captureException, init, setUser } from '@sentry/react'
import { PropsWithChildren, useCallback, useLayoutEffect, useState } from 'react'
import { ENV } from 'shared/configs/env'
import { sentryServiceContext } from 'shared/lib/sentry/sentry-service-context'

export const SentryServiceProvider = ({ children }: PropsWithChildren) => {
  const [sentryInit, setSentryInit] = useState(false)

  useLayoutEffect(() => {
    const dsn = ENV.SENTRY_DNS
    const release = ENV.SENTRY_RELEASE

    if (!dsn && !release) return

    init({
      dsn: dsn,
      release: release,
      integrations: [new BrowserTracing()],
      tracesSampleRate: 1.0,
    })

    setSentryInit(true)
  }, [])

  const setSentryUser: typeof setUser = useCallback(
    (user) => {
      if (!sentryInit) return

      setUser(user)
    },
    [sentryInit]
  )

  const reportException: typeof captureException = useCallback(
    (error, context) => {
      if (!sentryInit) return ''

      return captureException(error, context)
    },
    [sentryInit]
  )

  const Provider = sentryServiceContext.Provider

  return (
    <Provider
      value={{
        setSentryUser: setSentryUser,
        reportException: reportException,
      }}>
      {children}
    </Provider>
  )
}
