import { action, autorun, makeAutoObservable, observable } from 'mobx'
import { i18n } from 'shared/configs/i18n'
import { ymUse } from 'shared/lib/ya-metrics-wrap'

import type { AlertState } from '../lib/types'

export type AlertKeys =
  | 'callOrdered'
  | 'orderCreated'
  | 'timeIsNotAvailable'
  | 'priceChanged'
  | 'fixErrors'
  | 'productNotFound'
  | 'anyError'
  | 'minMaxChanged'

export class AlertStore {
  @observable private _alert: AlertKeys | null = null

  constructor() {
    makeAutoObservable(this, {}, { deep: true })

    autorun(() => {
      if (this._alert) {
        const targetText = this._alert.charAt(0).toUpperCase() + this._alert.slice(1)

        ymUse({
          action: 'Show',
          source: 'AlertStore',
          target: `${targetText}Alert`,
        })
      }
    })
  }

  @action public setAlert = (alert: AlertKeys) => {
    switch (this._alert) {
      case 'priceChanged':
        break
      case 'productNotFound':
        if (alert === 'priceChanged') this._alert = alert

        break
      case 'timeIsNotAvailable':
        if (alert === 'priceChanged' || alert === 'productNotFound') this._alert = alert

        break

      default:
        this._alert = alert
        break
    }
  }

  @action public hideAlert = () => {
    this._alert = null
  }

  get getAlert(): AlertState | null {
    switch (this._alert) {
      case 'anyError':
        return { title: i18n.t('Alert.Text.AnyError'), severity: 'error', autoHide: true }
      case 'callOrdered':
        return { title: i18n.t('Alert.Text.CallOrdered'), severity: 'success', autoHide: true }
      case 'fixErrors':
        return { title: i18n.t('Alert.Text.FixErrors'), severity: 'error', autoHide: true }
      case 'orderCreated':
        return { title: i18n.t('Alert.Text.OrderCreated'), severity: 'success', autoHide: true }
      case 'priceChanged':
        return {
          text: i18n.t('Alert.Text.PriceChanged.Body'),
          title: i18n.t('Alert.Text.PriceChanged.Title'),
          severity: 'info',
          autoHide: false,
        }
      case 'productNotFound':
        return { title: i18n.t('Alert.Text.productNotFound'), severity: 'info', autoHide: true }
      case 'timeIsNotAvailable':
        return { title: i18n.t('Alert.Text.TimeIsNotAvailable'), severity: 'error', autoHide: true }
      case 'minMaxChanged':
        return { title: i18n.t('Alert.Text.MinMaxChanged'), severity: 'error', autoHide: true }
      default:
        return null
    }
  }
}
