import { OutlinedInputProps } from '@mui/material/OutlinedInput'
import { styled } from '@mui/material/styles'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import { theme } from 'app/providers/theme-provider/lib/theme'

interface Props {
  afterforselect?: string
}

export const CustomOutlinedTextField = styled((props: Props & TextFieldProps) => (
  <TextField
    data-testid="TextField-2a1ae5e1d04941b497c645402d0df853"
    size="medium"
    variant="filled"
    autoComplete="off"
    InputProps={{ disableUnderline: true } as Partial<OutlinedInputProps>}
    InputLabelProps={{ style: { color: theme.palette.secondary.main } }}
    sx={{
      '& .MuiSelect-select': {
        ':after': {
          content: props?.afterforselect ? `"${props.afterforselect}"` : undefined,
        },
      },
    }}
    {...props}
  />
))(({ theme }) => ({
  width: 'calc(max-content + 1px)',

  '& .MuiFilledInput-root': {
    overflow: 'hidden',
    borderRadius: '8px',
    backgroundColor: theme.palette.common.white,
    transition: theme.transitions.create(['border-color', 'outline-color']),
    border: '1px solid #0000003B',

    '&:hover': {
      backgroundColor: theme.palette.common.white,
      borderColor: '#000000',
    },

    '&.Mui-focused': {
      backgroundColor: theme.palette.common.white,
      outline: `2px solid ${theme.palette.primary.main}`,
      borderColor: 'transparent',
    },

    '&.Mui-error': {
      border: '1px solid',
      outline: 0,
      borderColor: theme.palette.error.main,
    },
  },
}))
