import { Box, Container, Stack } from '@mui/material'
import { PropsWithChildren } from 'react'
import img from 'shared/assets/images/Smile.png'
import { Header } from 'widgets/header'

export const PageTemplate = ({ children }: PropsWithChildren) => {
  return (
    <Box
      data-testid="Box-abb8fe8d4ee24e66963a12ba6e303424"
      height={'100vh'}
      display={'grid'}
      gridTemplateRows={'max-content 10fr'}>
      <Header />

      <Container>
        <Stack alignItems={'center'} justifyContent={'center'} height={'100%'} spacing={3}>
          <img src={img} alt="smile" />

          {children}
        </Stack>
      </Container>
    </Box>
  )
}
