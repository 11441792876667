import { ENV } from 'shared/configs/env'

type Args = Parameters<typeof ym>

type Action = 'Show' | 'Click' | 'Input'

interface UseArgs {
  source: string
  action: Action
  target: string
}

export const ymUse = (useArgs: UseArgs, params?: Args['2']) => {
  if (!ENV.METRIC_ID) return

  const { action, source, target } = useArgs

  const eventName = [source, action, target].join('-')

  const metricParams = {
    eventName: eventName,

    action: action,
    source: source,
    target: target,

    params,
  }

  ym(+ENV.METRIC_ID, 'reachGoal', 'all-js-events', metricParams)

  console.info(`Sended yandex metric payload: ${JSON.stringify(metricParams)}`)
}
