/* eslint-disable promise/no-promise-in-callback */
// TODO добавил пока игнор линтинга не смотря на то что сделал как рекомендует дока обернув промис функции в setTimeOut
// eslint всё равно ругается, потом надо будет разобраться в чём проблема
import { Mutation, Query, QueryKey, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useAlertStore } from 'entities/alert'
import { useCallback, useLayoutEffect } from 'react'
import { useErrorBoundary } from 'react-error-boundary'
import { getAuth, setAuth, usePostAuthAnonymous } from 'shared/api'
import { ErrorType } from 'shared/api/gen/axios'
import { ApiErrorResponseResponse } from 'shared/api/gen/types'

const anyErrorIgnore = '/orders'

const isCriticalNotIgnore = '/users/me'

const authIgnore = '/auth/anonymous'

export const useHandleAuthError = () => {
  const { showBoundary } = useErrorBoundary()
  const { mutateAsync } = usePostAuthAnonymous({
    mutation: {
      onSuccess: setAuth,
    },
  })

  const queryClient = useQueryClient()

  const errorIsAuth = useCallback((error: any) => {
    const typedErr = error as AxiosError<ApiErrorResponseResponse>
    const errHandleCode = typedErr.response?.data.error.code

    return errHandleCode === 'notAuthorized' || errHandleCode === 'notAuthenticated'
  }, [])

  const errorIsConflictOrMalformed = (error: any) => {
    const typedErr = error as AxiosError<ApiErrorResponseResponse>
    const errHandleCode = typedErr.response?.data.error.code

    return (
      (errHandleCode === 'conflict' || errHandleCode === 'requestMalformed') &&
      typedErr.config?.url === anyErrorIgnore
    )
  }

  const errorIsInternal = useCallback((error: any) => {
    const typedAxios = error as AxiosError

    if (typedAxios.response?.data && 'error' in (typedAxios.response.data as object)) {
      const typedErrCode = (error as ErrorType<ApiErrorResponseResponse>).response?.data.error.code

      return typedErrCode === 'internal'
    }

    return typedAxios.response?.status === 500
  }, [])

  const authErrorHandle = useCallback(
    (
      request:
        | Query<unknown, unknown, unknown, QueryKey>
        | Mutation<unknown, unknown, unknown, unknown>
    ) => {
      const nowUserAuth = getAuth()

      const throwErr = (error: any) => {
        if (!errorIsAuth(error) && errorIsInternal(error)) showBoundary(error)
      }

      const resolveHandle = () => {
        //  Query
        if ('fetch' in request) {
          request.fetch().catch((err) => setTimeout(() => throwErr(err), 0))

          return
        }

        //  Mutation
        request.execute(request.state.variables).catch((err) => setTimeout(() => throwErr(err), 0))
      }

      const errHandle = (err: any) => {
        if (nowUserAuth?.refreshToken && errorIsAuth(err)) {
          return void mutateAsync({ data: {} })
            .then(() => setTimeout(resolveHandle, 0))
            .catch(() => setTimeout(throwErr, 0))
        } else {
          showBoundary(err)
        }
      }

      mutateAsync({ data: { refreshToken: nowUserAuth?.refreshToken } })
        .then(() => setTimeout(resolveHandle, 0))
        .catch((e) => setTimeout(() => errHandle(e), 0))
    },
    [errorIsAuth, errorIsInternal, mutateAsync, showBoundary]
  )

  const { setAlert } = useAlertStore()

  const anotherErrorHandler = useCallback(() => setAlert('anyError'), [setAlert])

  const errorHandle = useCallback(
    (
      error: Error,
      request:
        | Query<unknown, unknown, unknown, QueryKey>
        | Mutation<unknown, unknown, unknown, unknown>
    ) => {
      const typedErrorApi = error as AxiosError<ApiErrorResponseResponse>

      const checkIgnoreForUserMe =
        typedErrorApi.config?.url === isCriticalNotIgnore &&
        (typedErrorApi.config?.method === 'get' || typedErrorApi.config?.method === 'GET')

      if (errorIsAuth(error) && typedErrorApi.config?.url !== authIgnore) {
        return authErrorHandle(request)
      } else if (
        !errorIsInternal(error) &&
        !errorIsAuth(error) &&
        !errorIsConflictOrMalformed(error) &&
        !checkIgnoreForUserMe
      ) {
        return anotherErrorHandler()
      } else if (!errorIsAuth(error) && checkIgnoreForUserMe) {
        showBoundary(error)
      }
    },
    [anotherErrorHandler, authErrorHandle, errorIsAuth, errorIsInternal, showBoundary]
  )

  useLayoutEffect(() => {
    queryClient.setDefaultOptions({
      queries: { throwOnError: (err) => !errorIsAuth(err) && errorIsInternal(err) },
      mutations: { throwOnError: (err) => !errorIsAuth(err) && errorIsInternal(err) },
    })

    queryClient.getMutationCache().config = {
      onError: (error, _, _v, mutation) => errorHandle(error, mutation),
    }

    queryClient.getQueryCache().config = { onError: errorHandle }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
