import { ReactNode } from 'react'
import { Navigate, Outlet } from 'react-router-dom'

interface Props {
  children?: ReactNode
  authToken: string
  redirect: string
}

const ProtectedRoute = ({ children, authToken, redirect }: Props) => {
  if (!authToken) {
    return <Navigate to={redirect} replace />
  }

  return children || <Outlet />
}

export default ProtectedRoute
