import { Box, Paper, PaperProps, Stack, StackProps } from '@mui/material'
import { PropsWithChildren } from 'react'

interface Props extends PropsWithChildren {
  sx?: PaperProps['sx']
  spacing?: StackProps['spacing']
  elevation?: PaperProps['elevation']
}

export const PaperedBoxWithPaddings = ({ children, sx, spacing = 0, elevation }: Props) => {
  return (
    <Box
      data-testid="Box-36fca0ba7b7946fb9634f8257398c176"
      sx={sx}
      component={Paper}
      p="24px"
      elevation={elevation}>
      <Stack spacing={spacing}>{children}</Stack>
    </Box>
  )
}
