import { Box, styled } from '@mui/material'
import { captureException } from '@sentry/react'
import { useGetUserAndSave, useHandleAuthError } from 'entities/user'
import { ApiErrorPage } from 'pages/error-pages'
import { useCallback, useLayoutEffect } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { baseNameForDelivery, RoutersPathsWithRoot } from 'shared/configs/router-consts'
import PagesSkeletons from 'shared/ui/PagesSkeletons'

const FixedBox = styled(Box)(() => ({
  position: 'fixed',
  display: 'flex',
  top: 0,
  left: 0,
  height: '100%',
  width: '100vw',
  zIndex: 100000000,
}))

const Root = () => {
  const { pathname } = useLocation()

  useLayoutEffect(() => window.scrollTo(0, 0), [pathname])

  useHandleAuthError()

  const { isStale, isFetched } = useGetUserAndSave()

  const check = useCallback(
    () =>
      pathname.search(
        new RegExp(`${baseNameForDelivery}${RoutersPathsWithRoot.root.url}.`, 'g')
      ) === -1,
    [pathname]
  )

  return (
    <>
      {!isStale && !isFetched && (
        <FixedBox>
          <PagesSkeletons />
        </FixedBox>
      )}

      <Outlet />

      {check() && <Navigate to={'delivery/order'} />}
    </>
  )
}

export const RootRoute = () => (
  <ErrorBoundary onError={(err) => captureException(err)} fallback={<ApiErrorPage />}>
    <Root />
  </ErrorBoundary>
)
