import { Button, Container, Paper, Stack, TextField, Typography } from '@mui/material'
import { useCallback, useState } from 'react'
import { keyAdmin, loginAdmin, passwordAdmin } from 'shared/configs/credentials'

export const TempAuth = () => {
  const [login, setLogin] = useState('')
  const [password, setPassword] = useState('')

  const handleLogin = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()

      if (login === loginAdmin && password === passwordAdmin) {
        localStorage.setItem('test', keyAdmin)
        location.reload()
      }
    },
    [login, password]
  )

  return (
    <Container
      data-testid="Container-2fada7455fed4617b938b0009595dff8"
      maxWidth="xs"
      sx={{ display: 'flex', alignItems: 'center', height: '100vh' }}>
      <Paper elevation={3} sx={{ padding: 4 }}>
        <Stack alignItems={'center'}>
          <Typography variant="h4">Proil</Typography>

          <form onSubmit={handleLogin}>
            <TextField
              data-testid="TextField-09e7a800b8c44b6eb4a2f387eafd9df7"
              label="Login"
              variant="outlined"
              margin="normal"
              fullWidth
              value={login}
              onChange={(e) => setLogin(e.target.value)}
            />

            <TextField
              data-testid="TextField-1de1ac3eab1647458eef69fa82528201"
              label="Password"
              variant="outlined"
              type="password"
              margin="normal"
              fullWidth
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />

            <Button
              data-testid="Button-28879c562fd24d31a1ff7d3b3e9413fa"
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={{ marginTop: 2 }}>
              Login
            </Button>
          </form>
        </Stack>
      </Paper>
    </Container>
  )
}
