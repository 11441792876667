import CloseIcon from '@mui/icons-material/Close'
import { Dialog, DialogProps, IconButton, Stack, Typography } from '@mui/material'

import { PaperedBoxWithPaddings } from './PaperedBoxWithPaddings'

interface Props extends DialogProps {
  headerText: string
  handleClose: () => void
}

export const DialogCustomBase = ({ handleClose, headerText, ...props }: Props) => {
  return (
    <Dialog data-testid="Dialog-73c0188c1d9949578033fe40eb795b33" {...props}>
      <PaperedBoxWithPaddings sx={{ width: '600px', position: 'relative' }} elevation={10}>
        <IconButton
          data-testid="IconButton-3f91d3df8a2d410a8bd927e30dba3f7a"
          sx={{ position: 'absolute', top: 0, right: 0, padding: '12px' }}
          onClick={handleClose}>
          <CloseIcon />
        </IconButton>

        <Stack spacing={2}>
          <Typography variant="h5">{headerText}</Typography>

          {props.children}
        </Stack>
      </PaperedBoxWithPaddings>
    </Dialog>
  )
}
