import { createTheme, ThemeOptions } from '@mui/material'
import { ruRU as coreRuRU } from '@mui/material/locale'
import { ruRU } from '@mui/x-date-pickers/locales'
import fontsStyles from 'shared/assets/fonts/fonts.module.css'

const h1Style = `
font-size: 40px;
font-weight: 500;
line-height: 48px;
text-align: left;
`

const h4Style = `
font-size: 32px;
font-weight: 500;
line-height: 40px;
text-align: left;
`

const h5Style = `
font-size: 20px;
font-weight: 500;
line-height: 24px;
text-align: left;
text-wrap: nowrap;
`

const bodyStyle1 = `
font-size: 16px;
font-weight: 400;
line-height: 20px;
text-align: left;
white-space: pre-wrap;
`

const bodyStyle2 = `
font-size: 14px;
font-weight: 400;
line-height: 16px;
text-align: left;
white-space: pre-wrap;
`

const themeBase = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1144,
      xl: 1536,
    },
  },

  palette: {
    bgMainPaper: { main: '#F5F5F5' },
    colorButtonGradient: { main: 'linear-gradient(90deg, #FF7A00 0.15%, #FFA800 99.8%)' },
    text: { primary: '#000000', secondary: '#00000099', disabled: '#00000061' },
    primary: { main: '#FE910A', dark: '#C56D00', light: '#FFAB44', contrastText: '#FFFFFF' },
    secondary: { main: '#424242', dark: '#212121', light: '#757575', contrastText: '#FFFFFF' },
    error: { main: '#D32F2F', dark: '#C62828', light: '#EF5350', contrastText: '#FFFFFF' },
    warning: { main: '#EF6C00', dark: '#EF6C00', light: '#FF9800', contrastText: '#FFFFFF' },
    info: { main: '#FE910A', dark: '#C56D00', light: '#FFAB44', contrastText: '#FFFFFF' },
    success: { main: '#43A047', dark: '#1B5E20', light: '#4CAF50', contrastText: '#FFFFFF' },
    background: { default: '#FFFFFF', paper: '#FFFFFF' },
    action: {
      active: '#0000008F',
      hover: '#0000000A',
      selected: '#00000014',
      disabledBackground: '#0000001F',
      focus: '#0000001F',
      disabled: '#00000061',
    },
    divider: '#0000001F',
    common: {
      white: '#FFFFFF',
      black: '#000000DE',
    },
  },
  shape: {
    borderRadius: 8,
  },
  typography: {
    fontFamily: 'TT Norms Pro',
    button: {
      textTransform: 'none',
    },
  },
})

const otherOptions: ThemeOptions = {
  components: {
    MuiContainer: {
      styleOverrides: {
        maxWidthLg: {
          padding: '0px 12px !important',
          minWidth: '1024px',
          maxWidth: `${themeBase.breakpoints.values.lg} !important`,
        },
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        inputSizeSmall: { borderColor: '#0000003B', paddingTop: '8px', paddingBottom: '8px' },
      },
    },

    MuiMenu: {
      styleOverrides: {
        paper: {
          borderRadius: '8px',
        },
      },
    },

    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: '#0000000A !important',
          },
        },
      },
    },

    MuiToggleButton: {
      styleOverrides: {
        sizeSmall: {
          fontSize: '16px',
          fontWeight: 500,
          lineHeight: '24px',
          letterSpacing: '0px',
          textAlign: 'left',
        },
      },
    },

    MuiFilledInput: {
      defaultProps: {
        disableUnderline: true,
      },
    },

    MuiInputBase: {
      variants: [
        {
          props: { size: 'medium' },
          style: {
            height: '56px',
            minHeight: '56px',
          },
        },
        {
          props: { size: 'large' },
          style: {
            minHeight: '56px',
          },
        },
      ],

      styleOverrides: {
        input: {
          backgroundColor: themeBase.palette.common.white,
          fontSize: '16px',
          fontWeight: 500,
          lineHeight: '24px',
          letterSpacing: '0px',
          textAlign: 'left',
        },
      },
    },

    MuiLink: {
      styleOverrides: {
        underlineAlways: {
          textDecorationColor: 'inherit',
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        bar1Determinate: {
          borderRadius: '999px',
          backgroundColor: themeBase.palette.primary.main,
        },
        determinate: {
          height: 8,
          borderRadius: '999px',
          backgroundColor: themeBase.palette.grey[300],
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        fontSizeSmall: {
          fontSize: '16px',
        },
      },
    },

    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        rounded: { borderRadius: '16px' },
      },
    },

    MuiButton: {
      defaultProps: {
        disableElevation: true,
        size: 'medium',
      },
      styleOverrides: {
        sizeSmall: {
          fontSize: '14px',
        },
        sizeMedium: {
          lineHeight: '24px',
        },
        sizeLarge: {
          fontSize: '20px',
          fontWeight: 500,
          lineHeight: '40px',
          letterSpacing: '0px',
          textAlign: 'left',
          borderRadius: '12px',
        },
      },
    },

    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: '12px',
          color: themeBase.palette.common.black,
          backgroundColor: themeBase.palette.common.white,
        },
        icon: {
          padding: '7px 12px 7px 0px',
          margin: 0,
          height: '36px',
        },
      },
    },

    MuiAlertTitle: {
      styleOverrides: {
        root: {
          fontSize: '16px',
          fontWeight: 500,
          lineHeight: '24px',
          letterSpacing: '0.15px',
          textAlign: 'left',
          margin: 0,
        },
      },
    },

    MuiChip: {
      variants: [
        {
          props: { color: 'default' },
          style: {
            backgroundColor: themeBase.palette.secondary.light,
            color: themeBase.palette.secondary.contrastText,
          },
        },
      ],
      styleOverrides: {
        label: {
          padding: '4px 8px',
        },
        sizeMedium: { height: '28px' },
        root: {
          borderRadius: '8px',
        },
      },
    },

    MuiTypography: {
      defaultProps: {
        fontFamily: fontsStyles,
      },

      variants: [
        {
          props: { variant: 'body1_link' },
          style: {
            fontSize: '16px',
            fontWeight: 400,
            lineHeight: '20px',
            textAlign: 'left',
            textDecoration: 'underline',
            textDecorationThickness: '1px !important',
            textUnderlineOffset: '1px',
            transition: themeBase.transitions.create('textDecoration'),
            cursor: 'pointer',
            ':hover': {
              textDecoration: 'none',
            },
          },
        },
        {
          props: { variant: 'h5_link' },
          style: {
            fontSize: '20px',
            letterSpacing: '0px',
            fontWeight: 500,
            lineHeight: '24px',
            textAlign: 'left',
            textDecoration: 'underline',
            textDecorationThickness: '1px !important',
            textUnderlineOffset: '2px',
            transition: themeBase.transitions.create('textDecoration'),
            cursor: 'pointer',
            ':hover': {
              textDecoration: 'none',
            },
          },
        },

        {
          props: { variant: 'h5_regular' },
          style: {
            fontSize: '20px',
            letterSpacing: '0px',
            fontWeight: 400,
            lineHeight: '24px',
            textAlign: 'left',
            textDecoration: 'underline',
            cursor: 'pointer',
            ':hover': {
              textDecoration: 'none',
            },
          },
        },

        {
          props: { variant: 'alert_description' },
          style: {
            fontSize: '14px',
            letterSpacing: '0.15px',
            fontWeight: 500,
            lineHeight: '20.02px',
            textAlign: 'left',
          },
        },
      ],
      styleOverrides: {
        root: {
          letterSpacing: '0px',
          textDecorationSkipInk: 'none',
        },
        noWrap: { textWrap: 'nowrap' },
        h1: h1Style,
        h4: h4Style,
        h5: h5Style,
        body1: bodyStyle1,
        body2: bodyStyle2,
      },
    },
  },
}

export const theme = createTheme(themeBase, otherOptions, ruRU, coreRuRU)
