import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { PropsWithChildren, useRef } from 'react'

export const QueryClientShell = ({ children }: PropsWithChildren) => {
  const client = useRef(
    new QueryClient({
      defaultOptions: {
        queries: {
          retry: false,
          gcTime: 1000 * 60 * 3,
          refetchOnWindowFocus: false,
        },
        mutations: {
          retry: false,
        },
      },
    })
  )

  return <QueryClientProvider client={client.current}>{children}</QueryClientProvider>
}
