import { Box, Link, styled } from '@mui/material'

export const GridBox = styled(Box)(() => ({
  height: '72px',
  padding: '11px 0px',
  display: 'grid',
  gridTemplateColumns: 'auto 10fr auto',
}))

export const PaddingBox = styled(Box)(() => ({
  padding: '14px 0px 0px 0px',
}))

export const StyledLink = styled(Link)(({ theme }) => ({
  padding: '5px 8px',
  height: '30px',
  marginRight: '16px',
  borderRadius: theme.shape.borderRadius,
  transition: theme.transitions.create('background-color'),
  '&:hover': { backgroundColor: theme.palette.action.hover },
}))
