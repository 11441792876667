import { AlertColor, AlertTitle, Snackbar, Theme, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useCallback, useEffect, useRef, useState } from 'react'

import { useAlertStore } from '../lib/useAlertStore'
import { StyledAlert } from './styled'

export const CommonAlert = observer(() => {
  const { hideAlert, getAlert } = useAlertStore()

  const [open, setOpen] = useState(false)

  const timer = useRef<NodeJS.Timeout>()
  const includedTimer = useRef<NodeJS.Timeout>()

  const close = useCallback(() => {
    setOpen(false)
    includedTimer.current = setTimeout(hideAlert, 200)
  }, [hideAlert])

  useEffect(() => {
    clearTimeout(timer.current)
    clearTimeout(includedTimer.current)

    setOpen(!!getAlert)

    if (getAlert && getAlert.autoHide) {
      timer.current = setTimeout(close, 2000)
    }
  }, [close, getAlert, hideAlert])

  const getBgColor = useCallback((color?: AlertColor) => {
    switch (color) {
      case 'success':
        return {
          backgroundColor: (t: Theme) => t.palette.success.main,
        }

      case 'error':
        return {
          backgroundColor: (t: Theme) => t.palette.error.main,
        }

      case 'info':
        return {
          backgroundColor: (t: Theme) => t.palette.info.main,
        }

      default:
        break
    }
  }, [])

  return (
    <Snackbar
      data-testid="Snackbar-2af0ba95c87448b5a3b0a2a14810852f"
      open={open}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      // autoHideDuration={getAlert ? null : 2000}
      // autoHideDuration={2000}
      /*   onClose={close} */
    >
      <StyledAlert
        severity={getAlert?.severity}
        variant="filled"
        onClose={close}
        sx={{ ...getBgColor(getAlert?.severity) }}>
        <AlertTitle>{getAlert?.title}</AlertTitle>
        <Typography variant="alert_description">{getAlert?.text}</Typography>
      </StyledAlert>
    </Snackbar>
  )
})
