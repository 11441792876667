import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'

import ru from './locales/ru/translation.json'

// eslint-disable-next-line import/no-named-as-default-member
void i18next.use(initReactI18next).init({
  debug: false,
  fallbackLng: 'ru',
  cleanCode: true,
  supportedLngs: ['ru'],
  resources: { ru: { translation: ru } },
  preload: ['ru'],
  interpolation: {
    escapeValue: false,
    skipOnVariables: false,
  },
})

export default i18next
