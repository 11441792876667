import { CssBaseline, GlobalStyles, ThemeProvider as MuiThemeProvider } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { PropsWithChildren } from 'react'

import { theme } from '../lib/theme'

const stringTheme = `body {background-color: ${theme.palette.bgMainPaper?.main ?? ''} !important;}`

export const ThemeProvider = observer(({ children }: PropsWithChildren) => {
  return (
    <MuiThemeProvider theme={theme}>
      <GlobalStyles styles={stringTheme} />
      <CssBaseline enableColorScheme={true}>{children}</CssBaseline>
    </MuiThemeProvider>
  )
})
