import { Button } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { StyledSnackbar } from './styled'

export const CookieAlert = () => {
  const { t } = useTranslation()

  const [open, setOpen] = useState(!!localStorage.getItem('cookie'))

  const handleSaveCookie = () => {
    localStorage.setItem('cookie', '{"is-approved":true}')
    setOpen(true)
  }

  return (
    <StyledSnackbar
      data-testid="StyledSnackbar-4ce9a166df074521ad3c68e1e4f4382d"
      open={!open}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      message={t('Cookie.Message')}
      action={
        <Button color="secondary" variant="contained" onClick={handleSaveCookie}>
          Хорошо
        </Button>
      }
    />
  )
}
