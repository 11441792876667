import { SxProps, Theme } from '@mui/material'
import Link, { LinkProps } from '@mui/material/Link'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'
import { translationsKey } from 'shared/configs/i18n'
import { AppRoutesNames } from 'shared/configs/router-consts'
import useTypedNavigate from 'shared/lib/hooks/useTypedNavigate'

export interface WithMetricProps {
  metric: () => void
}

export interface RequiredPropsLinkRouter {
  translationkey: translationsKey
  to: AppRoutesNames
  metric: () => void
}
interface Props extends RequiredPropsLinkRouter, LinkProps {}

const sxDefault: SxProps<Theme> = {
  height: '30px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '5px 8px',
  transition: (t) => t.transitions.create('background-color'),
}

export const LinkRouter = ({ metric, to, ...props }: Props) => {
  const { t } = useTranslation()
  const { typedNavigate } = useTypedNavigate()

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement>) => {
      event.preventDefault()
      if (metric) metric()

      typedNavigate({ key: to })
    },
    [metric, to, typedNavigate]
  )

  return (
    <>
      <Link
        sx={{
          '&:hover':
            !props.underline || props.underline === 'none'
              ? { backgroundColor: (t) => t.palette.action.hover, borderRadius: '8px' }
              : 'none',
          ...(props.sx ?? sxDefault),
        }}
        component={RouterLink}
        color="inherit"
        underline={props.underline ?? 'none'}
        variant={props.variant ?? 'body1'}
        onClick={handleClick}
        {...props}>
        {t(props.translationkey)}
      </Link>
    </>
  )
}
