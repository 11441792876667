import { forwardRef } from 'react'
import { IMaskInput } from 'react-imask'

interface InputMaskProps {
  onChange: (event: { target: { name: string; value: string } }) => void
  name: string
}

export const PhoneInputMask = forwardRef<HTMLInputElement, InputMaskProps>(
  function PhoneInputMask(props, ref) {
    const { onChange, ...other } = props

    return (
      <IMaskInput
        {...other}
        mask="#(000) 000-00-00"
        definitions={{
          '#': /[7-8]/,
        }}
        placeholder="+7"
        inputRef={ref}
        onAccept={(value: string) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    )
  }
)
