// eslint-disable-next-line import/no-named-as-default
import { uuid4 } from '@sentry/utils'
import axios, { AxiosError, AxiosRequestConfig } from 'axios'
import { assoc } from 'lodash/fp'
import get from 'lodash/fp/get'
import { ENV } from 'shared/configs/env'

import { getAuth } from './model/userAuth'

const AUTH_HEADER = 'Authorization'
const baseURL = `${ENV.BASE_URL ?? ''}`

const AXIOS_INSTANCE = axios.create({ baseURL: baseURL + '/api/user' })

export const authInstance = <T>(config: AxiosRequestConfig): Promise<T> =>
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  AXIOS_INSTANCE({ ...config, timeout: 45000 }).then(get('data'))

AXIOS_INSTANCE.interceptors.request.use((request) => {
  request = assoc(['headers', AUTH_HEADER], getAuth()?.accessToken, request)

  return assoc(['headers', 'X-Request-ID'], uuid4(), request)
})

export type ErrorType<Error> = AxiosError<Error>
