import { Checkbox, Fade, FormControlLabel, Link, Stack, Typography } from '@mui/material'

interface Props {
  checked: boolean
  text: string
  linkedText: string
  link: string
  onChange: () => void
  alertText?: string
  showAlert?: boolean
}

export const CheckBoxWithLinkedLabel = ({
  checked,
  text,
  linkedText,
  link,
  onChange,
  alertText,
  showAlert,
}: Props) => (
  <Stack spacing={0}>
    <FormControlLabel
      control={<Checkbox checked={checked} onChange={onChange} />}
      labelPlacement="end"
      label={
        <Typography variant="body1">
          {text}&nbsp;
          <Link target="_blank" href={link} color="inherit" variant="body1_link">
            {linkedText}
          </Link>
        </Typography>
      }
    />

    <Fade in={showAlert}>
      <Typography variant="caption" whiteSpace={'pre'} color={'error'}>
        {alertText}
      </Typography>
    </Fade>
  </Stack>
)
