import { Snackbar, styled } from '@mui/material'

export const StyledSnackbar = styled(Snackbar)((t) => ({
  '& .MuiSnackbarContent-root': {
    width: '830px',
    height: '52px',
    backgroundColor: t.theme.palette.bgMainPaper?.main,
    color: t.theme.palette.text.primary,
    fontSize: '16px',
    padding: '8px 16px 8px 16px',
    lineHeight: '20px',
  },
  '& .MuiSnackbarContent-action': {
    marginLeft: 0,
  },
}))
