import { Button, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { PageTemplate } from './PageTemplate'

export const ApiErrorPage = () => {
  const handleClick = () => {
    window.location.reload()
  }

  const { t } = useTranslation()

  return (
    <PageTemplate data-testid="PageTemplate-d8c60b0f980d40ebb2466f95164d2029">
      <Typography variant="h1">{t('ErrorPage.ServiceUnavailable.Title')}</Typography>

      <Typography variant="body1" textAlign={'center'}>
        {t('ErrorPage.ServiceUnavailable.Body')}
      </Typography>

      <Button
        data-testid="Button-91c6446d4a0a42b2b92ea9136766b803"
        sx={{ width: '300px' }}
        onClick={handleClick}
        variant="contained"
        color="secondary"
        size="large">
        {t('ErrorPage.ServiceUnavailable.Button')}
      </Button>
    </PageTemplate>
  )
}
