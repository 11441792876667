import { Auth } from '../types'

const AUTH_JWT_KEY = 'userTokens'

export const setAuth = (userAuth: Auth) =>
  localStorage.setItem(AUTH_JWT_KEY, JSON.stringify(userAuth))

export const removeAuth = () => localStorage.removeItem(AUTH_JWT_KEY)

export const getAuth = () => {
  const userAuth = localStorage.getItem(AUTH_JWT_KEY)

  if (userAuth) return JSON.parse(userAuth) as Auth

  return null
}
