import { Stack } from '@mui/material'
import { CommonAlert } from 'entities/alert'
import { CookieAlert } from 'features/cookie-alert'

import { QueryClientShell } from './providers/query-client-shell'
import { SentryServiceProvider } from './providers/sentry-service-provider'
import { ThemeProvider } from './providers/theme-provider'
import { AppRoutes } from './routers'

const App = () => (
  <ThemeProvider>
    <QueryClientShell>
      <SentryServiceProvider>
        <Stack minHeight={'100vh'}>
          <AppRoutes />

          <CommonAlert />
          <CookieAlert />
        </Stack>
      </SentryServiceProvider>
    </QueryClientShell>
  </ThemeProvider>
)

export default App
