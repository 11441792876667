import { Box, Container, Fade, Skeleton, Stack } from '@mui/material'

const PagesSkeletons = () => {
  return (
    <Fade data-testid="Fade-9caba82b2529482eb7c124eb17e088d0" in>
      <Box
        display={'grid'}
        flexGrow={'1'}
        width={'100vw'}
        gridTemplateRows={'max-content 1fr max-content'}
        sx={{ backgroundColor: (t) => t.palette.background.paper }}>
        <Skeleton height={72} variant="rectangular" />

        <Container sx={{ marginTop: '40px', padding: '0px !important' }}>
          <Stack spacing={2}>
            <Skeleton height={120} variant="rectangular" />

            <Skeleton height={120} variant="rectangular" />

            <Skeleton height={120} variant="rectangular" />
          </Stack>
        </Container>

        <Skeleton height={200} variant="rectangular" />
      </Box>
    </Fade>
  )
}

export default PagesSkeletons
