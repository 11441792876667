import { Alert, styled } from '@mui/material'

export const StyledAlert = styled(Alert)((t) => ({
  width: '440px',
  minHeight: '52px',
  color: t.theme.palette.success.contrastText,
  '& .MuiAlert-message': {
    width: '328px',
  },
  '& .MuiAlert-action': {
    margin: 0,
    '& .MuiSvgIcon-root': {
      fontSize: '20px',
    },
  },
}))
