type RoutesPathsWithChild<T extends string | number | symbol, Q> = Record<
  T,
  { url: string; child?: Q }
>

type RoutesPaths<T extends string | number | symbol> = Record<T, { url: string }>

export const baseNameForDelivery = '/delivery'

export enum RootRouterNames {
  root = 'root',
}

export enum AppRoutesNames {
  main = 'main',
  orders = 'orders',
  aboutProil = 'aboutProil',
  contacts = 'contacts',
  documents = 'documents',
  any = 'any',
}

export enum OrdersChildNames {
  finishing = 'finishing',
}

export const OrdersChildRoutes: RoutesPaths<OrdersChildNames> = {
  finishing: { url: `${baseNameForDelivery}/order/finishing` },
}

export const RoutersPaths: RoutesPathsWithChild<AppRoutesNames, RoutesPaths<OrdersChildNames>> = {
  main: { url: `${baseNameForDelivery}/order`, child: OrdersChildRoutes },
  orders: { url: `${baseNameForDelivery}/orders` },
  aboutProil: { url: `${baseNameForDelivery}/about` },
  contacts: { url: `${baseNameForDelivery}/contacts` },
  documents: { url: `${baseNameForDelivery}/files` },
  any: { url: '*' },
} as const

export const RoutersPathsWithRoot: RoutesPathsWithChild<
  RootRouterNames,
  RoutesPaths<AppRoutesNames>
> = {
  root: { url: '/', child: RoutersPaths },
}
