import { RequiredPropsLinkRouter } from 'features/link-router'
import { WithMetricProps } from 'features/link-router/ui/LinkRouter'
import { AppRoutesNames } from 'shared/configs/router-consts'
import { ymUse } from 'shared/lib/ya-metrics-wrap'

export const linksConst: (RequiredPropsLinkRouter & WithMetricProps)[] = [
  {
    translationkey: 'Link.Orders',
    to: AppRoutesNames.orders,
    metric() {
      ymUse({ action: 'Click', source: 'Header', target: 'MyOrdersLink' })
    },
  },
  {
    translationkey: 'Link.HowWork',
    to: AppRoutesNames.aboutProil,
    metric() {
      ymUse({ action: 'Click', source: 'Header', target: 'HowWorkLink' })
    },
  },
  {
    translationkey: 'Link.Contacts',
    to: AppRoutesNames.contacts,
    metric() {
      ymUse({ action: 'Click', source: 'Header', target: 'ContactsLink' })
    },
  },
]
