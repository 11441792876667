import { Link, useSearchParams } from 'react-router-dom'
import LogoSvgL from 'shared/assets/svg/logo-l.svg?react'
import LogoSvgS from 'shared/assets/svg/logo-s.svg?react'
import { RoutersPaths } from 'shared/configs/router-consts'

interface Props {
  size: 'large' | 'small'
  color: string
}

export const LinkedLogo = ({ size, color }: Props) => {
  const [searchParams] = useSearchParams()

  return (
    <Link to={{ pathname: RoutersPaths.main.url, search: searchParams.toString() }}>
      {size === 'large' ? <LogoSvgL fill={color} /> : <LogoSvgS fill={color} />}
    </Link>
  )
}
