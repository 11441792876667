import { Button, Stack } from '@mui/material'
import { AlertKeys } from 'entities/alert'
import { useUserStore } from 'entities/user'
import { ChangeEvent, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { usePostUserMeUpdate } from 'shared/api'
import linkConsentCall from 'shared/assets/pdf/Согласие на обратный звонок.pdf'
import { CheckBoxWithLinkedLabel } from 'shared/ui/CheckBoxWithLinkedLabel'
import { CustomOutlinedTextField } from 'shared/ui/CustomOutlinedTextField'
import { DialogCustomBase } from 'shared/ui/DialogCustomBase'
import { PhoneInputMask } from 'shared/ui/PhoneInputMask'

interface Props {
  handleCloseDialog: () => void
  openDialog: boolean
  setOpenAlert: (alertStatus: AlertKeys) => void
}

export const CallMeDialog = ({ handleCloseDialog, openDialog, setOpenAlert }: Props) => {
  const { t } = useTranslation()
  const { user } = useUserStore()

  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')

  const [isFocusName, setIsFocusName] = useState(false)
  const [isFocusPhone, setIsFocusPhone] = useState(false)
  const [acceptOffert, setAcceptOffert] = useState(false)

  const handleChangeName = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
    setName(e.target.value)

  const handleChangePhone = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const newValue = e.target.value.replace(/\D/g, '')
    setPhone(newValue)
  }

  const handleSuccess = useCallback(() => {
    setName('')
    setPhone('')
    setIsFocusName(false)
    setIsFocusPhone(false)
    setAcceptOffert(false)
    setOpenAlert('callOrdered')
    handleCloseDialog()
  }, [handleCloseDialog, setOpenAlert])

  const { mutate } = usePostUserMeUpdate({
    mutation: {
      onSuccess: () => {
        handleSuccess()
      },
    },
  })

  const handleChangeOffert = () => setAcceptOffert((v) => !v)

  const [isValidName, setIsValidName] = useState(true)
  const [isValidPhone, setIsValidPhone] = useState(true)
  const [isValidAcceptOffert, setIsValidAcceptOffert] = useState(true)

  const handleSend = useCallback(() => {
    setIsValidAcceptOffert(acceptOffert)

    if (name.length < 2) setIsValidName(false)

    if (phone.length < 11) setIsValidPhone(false)

    if (name.length < 2 || phone.length < 11 || !acceptOffert) {
      setOpenAlert('fixErrors')

      return
    }

    const checkUser = name && user?.name === name
    const checkPhone = phone && user?.phone === phone

    if (checkUser && checkPhone) {
      handleSuccess()
    } else {
      mutate({
        data: {
          name: name,
          email: user?.email ?? '',
          phone: phone,
        },
      })
    }
  }, [
    acceptOffert,
    handleSuccess,
    mutate,
    name,
    phone,
    setOpenAlert,
    user?.email,
    user?.name,
    user?.phone,
  ])

  const helperTextForName = useMemo(() => {
    return name.length < 2 && (isFocusName || !isValidName)
      ? t('MainPage.HelperText.FillField')
      : ''
  }, [isFocusName, isValidName, name, t])

  const helperTextForPhone = useMemo(() => {
    return phone.length < 11 && (isFocusPhone || !isValidPhone)
      ? t('MainPage.HelperText.FillField')
      : ''
  }, [isFocusPhone, isValidPhone, phone, t])

  return (
    <DialogCustomBase
      data-testid="DialogCustomBase-3010e1feab9349d0b177a32251be3732"
      headerText={t('Button.CallMe')}
      handleClose={handleCloseDialog}
      open={openDialog}
      onClose={handleCloseDialog}>
      <Stack spacing={2}>
        <CustomOutlinedTextField
          data-testid="CustomOutlinedTextField-702ae70b6b014ea0ba0a8307380e844d"
          label={t('CallMeDialog.Label.Name')}
          type="text"
          value={name}
          onChange={handleChangeName}
          helperText={helperTextForName}
          error={!!helperTextForName}
          onBlur={() => setIsFocusName(true)}
          inputProps={{ maxLength: 100 }}
        />
        <CustomOutlinedTextField
          data-testid="CustomOutlinedTextField-1c1ad22f94514972875428985dd20cec"
          label={t('CallMeDialog.Label.Phone')}
          type="tel"
          value={phone}
          onChange={handleChangePhone}
          helperText={helperTextForPhone}
          error={!!helperTextForPhone}
          onBlur={() => setIsFocusPhone(true)}
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          InputProps={{ inputComponent: PhoneInputMask as any }}
        />

        <CheckBoxWithLinkedLabel
          data-testid="CheckBoxWithLinkedLabel-4b87e1fa86d148a68c28a2e272f5fc45"
          text={t('SuccessAs.AgreePersonData')}
          linkedText={t('SuccessAs.PersonData')}
          checked={acceptOffert}
          link={linkConsentCall}
          onChange={handleChangeOffert}
          alertText={t('SuccessAs.Alert')}
          showAlert={!isValidAcceptOffert}
        />

        <Button
          data-testid="Button-2fb3a479fe0247acb005368396da0133"
          size="large"
          variant="contained"
          onClick={handleSend}
          color="secondary">
          {t('CallMeDialog.Button')}
        </Button>
      </Stack>
    </DialogCustomBase>
  )
}
